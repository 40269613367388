import React, { useEffect } from "react";



const Update = () => {

  return (
    <div
      style={{
        padding: '24px'
      }}
    >
      Hello
    </div>
  );
}
export default Update;
